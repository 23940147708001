@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

body {
  font-family: "Roboto", sans-serif;
}

h1 {
  font-weight: 400;
}

h4 {
  font-weight: 300;
}

h1,
h4 {
  margin: 0;
}

h1 {
  margin-bottom: 0.5rem;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

svg:not(:root).svg-inline--fa {
  font-size: 36px;
  margin: 1rem 0.5rem 0;
  color: white;
}

.App-link {
  color: #61dafb;
}

form {
  text-align: left;
  margin-top: 1rem;
}

.form-header {
  text-align: center;
}

label {
  font-size: 22px;
  display: block;
}

input,
textarea {
  color: white;
  background: #282c34;
  border: none;
  border-bottom: 2px solid white;
  width: 100%;
  outline: none;
  padding: 0.5rem;
}

input {
  font-size: 22px;
}

textarea {
  font-size: 16px;
}

button.submit {
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 3px;
  background: white;
  color: #282c34;
}

svg:not(:root).svg-inline--fa:hover {
  color: #61dafb;
}
